 .material-icons {

  display: block;
margin-left: 10%;
  margin-right: 10%;
display: inline;
}
::-webkit-scrollbar {
  width: 0.25rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #727473;
}
.mainBody {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.menuSection{
  width: 25%;
  height: 100vh;
  overflow: hidden;
  float: left;
position: relative;
}
/* Dark */
 .menuSectiondark {

  background-color: #2f3136;
 
}

/* Light */
 .menuSectionlight {
  
  background-color: #dadada;
}

.menuSectionGroup {
  margin: 40px 30px 30px 20px;
  width: 90%;
  height: 30vh;
  border: 1px solid white;
  overflow-y: auto;
}

.menuSectionGroup p,
.friendList p,
.addFriend p {
  font-size: 15px;
  color: white;
  letter-spacing: 0.3em;
  text-align: center;
}

li {
  list-style: none;
}
.addfriendList {
  padding-top: 30px;
}
.addfriendList li,
.groupList li {
  padding: 5px;
  list-style: none;
  font-size: 20px;
  margin-top: 12px;
}
.groupListdark li{
    color: white;
}
.justChat{
  letter-spacing: 0.2em;
  padding-bottom: 10px;
 
  text-align: center;
}
.justChatdark {

  color: white;
  border-bottom: 1px solid white;
}
.justChatlight {
     color: rgb(0, 0, 0);
    border-bottom: 1px solid rgb(0, 0, 0);
}




.addfriendList li img {
  padding-right: 5px;
  width: 15%;
  border-radius: 50%;
}
.friendName
{
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 50px;
  padding-top: 10px;
}
.friendNameImgName,.recentNameImgName{
    margin-top: 20px;
}
.friendNameImgName .text,.recentNameImgName .text{
    font-size: 15px;
    
}
.friendNameImgName img{
    padding-right: 5px;
  width: 50%;
  border-radius: 50%; 
  
}

.friendName p img{
  width:40%;
  border-radius: 50%;
  margin-bottom: 20px;
  cursor:pointer
}
.friendName p .rersName{
  display: none;
}
.recentNameImgName img{
    padding-right: 5px;
    width: 15%;
    border-radius: 50%; 
}
.addfriendListdark{
    color: white;
}
.groupList li {
  cursor: pointer;
}
.groupList li img {
  width: 18%;
}
.createRoom,
.manageAccount {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow-y: scroll;
  animation: leftToright 0.3s linear;
  padding: 50px 20px 20px 20px;
}
.createRoom .manageAccount p {
  color: white;
  text-align: center;
}

.manageAccount .form-outline {
  padding-top: 30px;
}
.manageAccount .form-outline label {
  color: #3a7afa;
  padding-left: 10px;
  font-size: 15px;
  letter-spacing: 0.3em;
}
.manageAccount .form-outline label .material-icons {
  font-size: 20px;
}
.manageAccount .form-outline .form-control {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #555;
  letter-spacing: 0.2em;
}
.addFriend {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow-y: scroll;
  animation: leftToright 0.3s linear;
  padding: 50px 20px 20px 20px;
}

.addFriendInput {
  width: 80%;
  float: left;
  padding-left: 10px;
  display: flex;
}
.addFriendInput .form-control {
  border-radius: 20px;
  width: 120% !important;
  font-weight: bolder;
  letter-spacing: 0.2em;
  background-color: transparent;
  border:none;
  border-bottom: 2px solid grey;
}
.addFriendSearch {
  width: 20%;
  padding-top: 5px;
  display: flex;
  padding-left: 15px;
}

.addFriendSearch span {
  cursor: pointer;
}
.materialiconhdark .material-icons
{
    color: #000000;
}
.recentChat,
.groupList {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow-y: scroll;
  left: 0%;
  opacity: 1;
  padding: 0px 20px 20px 20px;
  transition: 0.5s linear;
  animation: leftToright 0.3s linear;
  font-weight: bolder;
}

@keyframes leftToright {
  0% {
    left: -100%;
    opacity: 0;
  }
  50% {
    left: -50%;
    opacity: 0.5;
  }
  100% {
    left: 0%;
    opacity: 1;
  }
}
.recentChat li img  {
  padding-right: 5px;
  width: 15%;
  margin-top: 15px;
}
.recentChat li{
    padding: 5px;
    list-style: none;
    font-size: 15px;
    letter-spacing: 0.2em;
    margin-top: 12px;
}
.recentChatdark li {
  color: white;
}
.recentChatlight li{
 color: rgb(0, 0, 0);
}
.selfInfo
{
  position: fixed;
  bottom: 0;
  width: 25%;
  height: 8vh;
  padding: 10px 10px 15px 10px;
}
.selfInfodark {
 
  background-color: #36373a;

}
.selfInfolight{

  background-color: #a5a5a5;

}
.selfInfo p img{
  width: 10%;
  border-radius: 50%;
}

.selfInfo p {
  font-weight: bolder;
  letter-spacing: 0.2em;
  color: white;
}


.chatSection
{
    width: 60%;
    height: 100vh;
    float: left;
    position: relative;
    overflow: hidden;
  
}
.chatSectiondark {
 
  background-color: #36393f;
}
.chatSectionlight {
  background-color: #ffffff;
}
 .chatbox {
    width: 100%;
  
    height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 30px;
  }
 .friendSection {
  width: 10%;
  height: 100vh;

  float: right;
  position: relative;
  overflow: hidden;
}
.friendSectionlight{
    background-color: #DADADA;
}
.friendSectiondark{
    background-color: #202225;
}
.controlMenu {
  width: 5%;
  height: 100vh;
  float: left;

  overflow-y: auto;
  background-color: #202225;
}
.controlMenu hr {
  border: 1px solid rgb(65, 69, 82);
  background-color: rgb(65, 69, 82);
}

.controlMenu li img {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  cursor: pointer;
}
.add {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
  cursor: pointer;
}


.material-icons {
  font-size: 26px;
  color: white;
}

.controlMenuListIcon .material-icons {
  margin-top: 35px;
  font-size: 40px;
  display: block;
  margin-left: auto;
  cursor: pointer;
  margin-right: auto;
}
.chatImage p {
  text-align: center;
  font-size: 30px;
  padding-top: 10px;
  letter-spacing: 0.3em;
}
.chatImagedark p{
    color: rgb(255, 255, 255);
}
.chatImage img {
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30%;
}
.modalpop {
  font-size: 12px;
  background-color: white;
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20%;
}
.modalpop > .headerpop {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modalpop > .content {
  width: 100%;
  padding: 10px 5px;
}
.modalpop > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modalpop > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  opacity: 1;
  top: -10px;
  font-size: 24px;
  background: #424242;
  border-radius: 18px;
  border: 1px solid #cfcece;
  outline: none;
}
.userSetting {
  float: right;
  margin: 5px 20px 0px 10px;
  cursor: pointer;
}
.switch {
  float: right;
  height: 34px;
  position: relative;
  width: 60px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #282a2e;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .slider {
  background-color: #ccc;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  border: 1px solid white;
  margin-bottom: 25px;
}
.form-outlineImage {
  padding-left: 30%;
}
.img-wrap {
  position: relative;

  overflow: hidden;
  border-radius: 50%;
}
.img-upload:before {
  content: "Edit";
  font-size: 10px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #000000;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
}
.img-upload:hover:before {
  opacity: 1;
}
img {
  width: auto;
  height: 100%;
}

label {
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
}

.btn-save {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 70px;
  font-weight: bolder;
}

.scanlines {
  overflow: hidden;
  mix-blend-mode: difference;
}

.scanlines::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background: repeating-linear-gradient(
    to bottom,
    transparent 0%,
    rgba(255, 255, 255, 0.048) .5%,
    transparent 1%
  );

  animation: fudge 7s ease-in-out alternate infinite;
}


@keyframes fudge {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0px, 2%);
  }
}
.openfriendListclass
{
  display: none;
}
.nouser{
  margin-top: 30px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  letter-spacing: 0.2em;
}
.welcomeMsg p:nth-child(1)
{
  font-size: 30px;
  font-weight: bolder;
  letter-spacing: 0.1em;
}
.welcomeMsg .btn{
  margin: 15px;
  background-color: #202225;
  color: white;
  outline:none
}

.crop-container {
 
  width: 1%;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}
.userifo
{
  color: white;
  margin-left: 20px;
  letter-spacing: 0.2em;
}
.blinkspan{
  animation: blinknoti 0.9s infinite;
}
@keyframes blinknoti {
  0%{
    opacity: 0;
  }
  25%{
    opacity: 0.5;
  }
  25%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
.updates{
  padding-top: 160px;

}