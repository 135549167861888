@media (max-width: 600px) {
  .controlMenu {
    position: fixed;
  padding: 10px;
    z-index: 1234;
    width: 100%;
    bottom: 0;
    float: none;
    height: 10vh;
    display: inline-block;
  }
  .selfInfo{
  
    width: 100%;
    height: 7vh;
    bottom:10vh;
    padding-bottom: 20px;
  }
  .controlMenu hr {
    display: none;
  }
  .controlMenu .material-icons {
   
    margin: 2%;
    display: inline-block;
  }
  .controlMenu::-webkit-scrollbar {
    width: 1rem;
  border-radius:50px
  }

  .controlMenu::-webkit-scrollbar-track {
    background: transparent;
  }

  .controlMenu::-webkit-scrollbar-thumb {
    background: #727473;
  }
  .menuSection {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: 123;
    float: none;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    top: 0; /* Stay at the top */
    left: 0;
  
  }
  @keyframes resback {
    100% {
      left: 0%;
      opacity: 1;
    }
    50% {
      left: -50%;
      opacity: 0.5;
    }
    0% {
      left: -100%;
      opacity: 0;
    }
  }
  .menuSectionres {
    width: 100%;
    opacity: 0;
    left: -100%;
    position: absolute;
    z-index: 123;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    top: 0; /* Stay at the top */
    left: -100;
  }
  @keyframes res {
    0% {
      left: 0%;
      opacity: 1;
    }
    50% {
      left: -50%;
      opacity: 0.5;
    }
    100% {
      left: -100%;
      opacity: 0;
    }
  }
  .chatSection {
    width: 100%;
    height: 100vh;
    float: none;
    position: relative;
    overflow: hidden;
  }
   .friendSectionres {
    width: 100%;
    height: 100vh;

    float: none;
    position: fixed;
    z-index: 127;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    top: 0; /* Stay at the top */
    right: 0;
 
  }
  .friendName p img{
    width:20%;

  }
  .friendName p .rersName{
    display: block;
  }
  .friendName
{
  
  padding-bottom: 150px;
}
  .friendSection {
    width: 100%;
    height: 50vh;
  padding-top: 10px;
    float: none;
    position: fixed;
    z-index: 127;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    top: 0; /* Stay at the top */
     right: -100%;
    
  }
  
  .openfriendListclass {
    position: fixed;
    z-index: 234;
    right: 0;
    margin: 15px;
    display: block;
    top: 9%;
    padding: 12px;
    overflow: hidden;

  }
  .openfriendListclass .material-icons {
    background-color: black;
    padding: 10px;
    border-radius: 20px;
  }
 .recentChat{
   padding-top: 40px;
   padding-bottom: 100px;
 }
 .updates{
  padding-top: 10px;
  
}
}
