.MessageSection {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color:#dad1d1 ;
}
.mainChatindi {
  padding: 100px 20px 60px 20px;
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.mainChatindi::-webkit-scrollbar {
  width: 0.25rem;
}

.mainChatindi::-webkit-scrollbar-track {
  background: transparent;
}

.mainChatindi::-webkit-scrollbar-thumb {
  background: #727473;
}
.Headerchatindi {
  background-color: rgb(65, 69, 82);
  width: 60%;
  height: 10vh;
  padding-top: 5px;
  color: white;
  position: fixed;
}
.Headerchatindi p img {
  width: 5%;
  margin: 5px 5px 5px 30px;
  border-radius: 50%;
}
.Headerchatindi p {
  font-size: 20px;
  font-weight: bolder;
  text-align: left;
}
.Headerchatindi p .material-icons{
  float:right;
  margin:20px 20px 20px 20px
}
.start {
  color: white;
  font-size: 30px;
  text-align: center;
  padding-top: 25%;
}
.indiForm  {
  height: 8vh;
  border-top: 1px solid  #2c2c2c;
  width: 60%;
  display: flex;
  font-size: 1.5rem;
  background-color: #2c2c2c;
  position: fixed;
  bottom: 0;
}

.indiForm  button {
  width: 10%;
  background-color: rgb(255, 255, 255);
}
.material-icons.micon {
  color: black;
}

.indiForm  textarea {
  width: 90%;
  font-size: 1em;
  background: rgb(253, 253, 253);
  color: rgb(0, 0, 0);
  outline: none;
border: none;
margin: 5px;
border-radius: 20px;
padding: 10px;
resize: none;

}
textarea::placeholder {
  color: rgb(0, 0, 0);
}
button {
  border: none;

  text-align: center;
  text-decoration: none;

  cursor: pointer;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.message {
  display: flex;
  align-items: center;
}
.message p {
  font-size: 14px;
  padding: 10px 10px 10px 10px;
  font-weight: bold;
  width: 50%;
  word-wrap:break-word;
}

.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background: #0b93f6;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 30px;
}
.message label {
    font-size: 10px;
    padding: 10px 10px 10px 10px;
    
  }
.received p {
  background: #e5e5ea;
  color: black;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 30px;

}
.sent p img{
  display: block;
  margin: auto;

}
.received p img{
  display: block;
  margin: auto;
}
.received p .material-icons{
  color: black;
  
}
.picker {
  position: absolute;
  z-index: 12;
  top: 47%;
  opacity: 1;
}
.filepicker{
  position: absolute;
  z-index: 12;
  top: 50%;
  opacity: 1;
}

.showPickerDivindi {
  padding: 15px 20px 20px 20px;
  cursor: pointer;
  background-color: #2c2c2c;
}
.showPickerDivindi .material-icons {
  color: white;
}

.infoDetailindi
{
    background-color: rgb(117, 117, 117);
    width: 50%;
    min-height: 30vh;
    overflow-y: auto;
    padding-top: 5px;
    color: white;
    right: 0;
    position: absolute;
    display: none;
   top: 10vh;
}  
.infoDetailindi label{
  color:black;
  display: none;
  padding-left:10px;
  padding-right:10px;
}
.infoDetailindi h4{
text-align: center;
  padding-bottom: 50px;
}
.infoDetailImgindi{
    background-color: #b1b1b1;
}
.infoDetailImgindi img
{
width: 20%;
display: block;
margin: auto;
transition: 0.3s linear;
}
.infoDetailImgindi img:hover
{
width: 60%;
transition: 0.3s linear;
}
.infoDetailindi p{
  font-size: 20px;
  letter-spacing: 0.3em;
  font-weight: bolder;
  padding: 10px;
  text-align: center;
}
.showPickerDivindi input[type="file"] {
  display: none;
}

#pict {
  cursor: pointer;
  background-color: transparent;
}
.showFilemodal img{
  width: 55%;
}
.buttonModal .btn{
  background-color: #2c2c2c;
  margin: 20px;
  padding: 10px;
}

.imageMessage
{
  display: flex;
}

.imageMessage a{
  height: 1vh;
}