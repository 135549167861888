@media (max-width: 600px) {
    .mainChatgroup {
        height: 72.8vh;
        padding-bottom: 60px;
    }

    .showPickerDivgroup {
        padding: 15px 10px 10px;
        cursor: pointer;
        background-color: #2c2c2c;
    }

    .pickergroup {
        position: absolute;
        z-index: 12;
        top: 42%;
        opacity: 1;
    }
    .Headerchat p {
        font-size: 20px;
        font-weight: bolder;
        text-align: left;
        padding-top: 3%;
    }
    .infoDetail {
        background-color: rgb(117, 117, 117);
        width: 100%;
        height: 84vh;
        position: absolute;
        z-index: 122;
    }
}
