@media (min-width: 300px) and (max-width: 600px) {
    .mainChatindi {
     margin-top: 20%;
        padding: 100px 10px 180px 10px;
    }
    .showPickerDivindi {
        padding: 15px 10px 10px 10px;
        cursor: pointer;
        background-color: #2c2c2c;
    }

    .picker {
        position: absolute;
        z-index: 12;
        top: 42%;
        opacity: 1;
    }
    .Headerchatindi p {
        font-size: 25px;
        font-weight: bolder;
        text-align: left;
        padding-top: 3%;
      }
    .Headerchatindi {
        background-color: rgb(65, 69, 82);
        width: 100%;
        height: 9.2vh;
        padding-top: 5px;
        color: white;
      }
      .Headerchatindi p img {
        width: 10%;
        margin: 5px 5px 5px 30px;
        border-radius: 50%;
       
      }
      .Headerchatindi p {
        font-size: 20px;
        font-weight: bolder;
        text-align: left;
      }
      .Headerchatindi p .material-icons{
        float:right;
        margin:6px 20px 10px 10px
      }
      .indiForm{
        bottom:78px;
        width: 100%;
      }
      .infoDetailindi
{
   
   top: 21npm startvh;
} 
}
@media (max-height:500px){
  .indiForm{
    bottom:47px;
    width: 100%;
    height: 12vh;
  }
  .Headerchatindi {
    background-color: rgb(65, 69, 82);
    width: 100%;
    height: 9.2vh;
    padding-top: 0px;
    color: white;
  }
  .Headerchatindi p img {
    width: 5%;
    margin: 0px 5px 5px 30px;
    border-radius: 50%;
   
  }
  .Headerchatindi p {
    font-size: 15px;
    font-weight: bolder;
    text-align: left;
  }
  .Headerchatindi p .material-icons{
    float:right;
    margin:0px 20px 0px 0px
  }
}
@media (max-width: 300px) {
  .mainChatindi {
     
    padding: 100px 20px 150px 0px;
}
.showPickerDivindi {
    padding: 15px 10px 10px 10px;
    cursor: pointer;
    background-color: #2c2c2c;
}

.picker {
    position: absolute;
    z-index: 12;
    top: 42%;
    opacity: 1;
}
.Headerchatindi p {
    font-size: 25px;
    font-weight: bolder;
    text-align: left;
    padding-top: 3%;
  }
.Headerchatindi {
    background-color: rgb(65, 69, 82);
    width: 100%;
    height: 9.2vh;
    padding-top: 0px;
    color: white;
  }
  .Headerchatindi p img {
    width: 5%;
    margin: 0px 5px 5px 30px;
    border-radius: 50%;
   
  }
  .Headerchatindi p {
    font-size: 15px;
    font-weight: bolder;
    text-align: left;
  }
  .Headerchatindi p .material-icons{
    float:right;
    margin:0px 20px 0px 0px
  }
  .indiForm{
    bottom:45px;
    width: 100%;
    height: 12vh;
  }
}