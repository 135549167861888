.MessageSectiongroup {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color:#dad1d1 ;
  }
 .mainChatgroup {
  padding: 20px 20px 60px 20px;
  
    height: 82.8vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
 .mainChatgroup::-webkit-scrollbar {
    width: 0.25rem;
  }
  
 .mainChatgroup::-webkit-scrollbar-track {
    background: transparent;
  }
  
 .mainChatgroup::-webkit-scrollbar-thumb {
    background: #727473;
  }
  .Headerchat {
    background-color: rgb(65, 69, 82);
    width: 100%;
    height: 9.2vh;
    padding-top: 5px;
    color: white;
  }
  .Headerchat p img {
    width: 5%;
    margin: 5px 5px 5px 30px;
    border-radius: 50%;
  }
  .Headerchat p {
    font-size: 20px;
    font-weight: bolder;
    text-align: left;
    
  }
  .start {
    color: white;
    font-size: 30px;
    text-align: center;
    padding-top: 25%;
  }
  .groupform {
    height: 8vh;
    border-top: 1px solid  #2c2c2c;
    width: 100%;
    display: flex;
    font-size: 1.5rem;
  }
  
  .groupform button {
    width: 10%;
    background-color: rgb(255, 255, 255);
  }
  .material-icons.micon {
    color: black;
  }
  
  .groupform input {
    width: 90%;
    font-size: 1em;
    background: rgb(253, 253, 253);
    color: rgb(0, 0, 0);
    outline: none;
  border: none;
    padding: 0 10px;
  }
  .groupform input::placeholder {
    color: rgb(0, 0, 0);
  }
  .groupform button {
    border: none;
  
    text-align: center;
    text-decoration: none;
  
    cursor: pointer;
  }
  .groupform button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .messagegroup {
    display: flex;
    align-items: center;
  }
  .messagegroup p {
    font-size: 14px;
    padding: 10px 10px 10px 10px;
    font-weight: bold;
    width: 50%;
  }
  .sentgroup p img{
    display: block;
    margin: auto;
  
  }
  .receivedgroup p img{
    display: block;
    margin: auto;
  }
  .receivedgroup p .material-icons{
    color: black;
    
  }

  .sentgroup {
    flex-direction: row-reverse;
  }
  
  .sentgroup p {
    color: white;
    background: #0b93f6;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 30px;
  }
  .messagegroup label {
      font-size: 10px;
      padding: 10px 10px 10px 10px;
      
    }
  .receivedgroup p {
    background: #e5e5ea;
    color: black;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 30px;
  }
  
  .pickergroup {
    position: absolute;
    z-index: 12;
    top: 50%;
    opacity: 1;
  }
  .showPickerDivgroup {
    padding: 15px 20px 20px 20px;
  
    background-color: #2c2c2c;
  }
  .showPickerDivgroup .material-icons {
    color: white;
    cursor: pointer;
  }
.infoDetail
{
    background-color: rgb(117, 117, 117);
    width: 50%;
    height: 80vh;
    overflow-y: auto;
    padding-top: 5px;
    color: white;
    right: 0;
    position: absolute;
    display: none;
   
}  

@keyframes topBottom {
    0%{
        height: 0vh;
    }
    50%{
        height: 30vh;

    }
    100%{
        height: 60vh;
 
    }
}
.infoDetailImg{
    background-color: #b1b1b1;
}
.infoDetailImg img
{
width: 60%;
display: block;
margin: auto;
}
.infoDetail p{
    font-size: 20px;
    letter-spacing: 0.3em;
    font-weight: bolder;
    padding: 10px;
}
.infoDetail_FriendList p,.groupaddfreind p
{

 font-size: 10px;
}
.infoDetail_FriendList li{
    padding: 10px;
}
.infoDetail_FriendList li img {
 width: 15%;   
margin-right: 20px;
border-radius: 50%;
}
.infoDetail .material-icons{
    float: right;
    margin-right: 10px;
    cursor:pointer
}
.groupaddfreind .form-outline .form-control{
    color: rgb(15, 15, 15);
    background-color: white;
    margin-right: 30px;
}
.groupaddfreind .material-icons{
    margin-right: 10px !important;
    cursor:pointer
}
.addGroup
{
    margin-top: 15px;
}
.myfriendlist
{
    border-top:1px solid black;
    padding: 10px;
}
.myfriendlist li,.addFriendtogroup li{
    padding: 10px;
}
.myfriendlist li img,.addFriendtogroup li img{
    width: 15%;
    border-radius: 50%;
}
.addFriendtogroup
{
    width: 100%;
    min-height: 10vh;
    padding: 10px;
}
.groupaddfreind .InputSearch{
    height: 5vh;
}
.admin
{
  float: right;
  margin: 15px;
  font-size: 12px;
  border:1px solid black;
  padding: 3px;
  color: black;
  border-radius: 10px;
}